<template>
  <v-card
    class="mx-auto elevation-0"
    height="100%"
  >
    <v-toolbar
      class="kalan-titles elevation-0"
      color="primary--text"
    >
      <v-toolbar-title>
        <span>{{ $vuetify.lang.t(`$vuetify.${model}.plural_name`) }}</span>
      </v-toolbar-title>
      <v-spacer></v-spacer>
      <div style="margin-right: 20px;">
        <v-text-field
          flat
          label="Buscar"
          hide-details
          v-model="search"
          append-icon="mdi-magnify"
        ></v-text-field>
      </div>
      <actions-button :item="{}" :actions="actions"></actions-button>
    </v-toolbar>
    <kalan-list
      :loading="loading"
      :getTitle="getTitle"
      :getSubTitle="getSubTitle"
      :getExtra="getExtra"
      :items="items"
      :search="search"
      :actions="listActions"
    >
    </kalan-list>
  </v-card>
</template>

<script>
import { mapGetters } from 'vuex';
import ApiService from '@/util/api.service'
import Users from '@/models/users.model'
import Crud from '@/util/crud.service'
import Dialog from '@/components/dialog'
import Form from '@/components/form'
import UsersService from '@/util/users.service'

export default {
  props: {
    model: {
      default: 'users'
    },
  },
  components: {
    'kalan-list': () => import('@/components/modulo/List.vue'),
    'actions-button': () => import('@/components/modulo/Actions.vue')
  },
  data() {
    return {
      items: [],
      loading: true,
      search: '',
      actions: [{
        help: window.VMA.$vuetify.lang.t(`$vuetify.${this.model}.create`),
        icon: 'mdi-plus',
        color: 'secondary',
        action: (item) => {
          Users.rol.visibility = this.getUser.rol === 'admin'
          Crud.create({
            item, schema: Users, model: 'users', url: '/auth/register'
          }).then((savedItem) => {
            console.log(savedItem)
            this.load()
          }).catch((err) => {
            console.error(err)
          });
        }
      }],
      //   {
      //   help: window.VMA.$vuetify.lang.t(`$vuetify.${this.model}.update`),
      //   icon: 'mdi-pencil',
      //   color: 'accent',
      //   action: (item) => {
      //     Crud.update({
      //       item,
      //       schema: Users,
      //       model: this.model,
      //       title: this.getTitle(item)
      //     }).then((savedItem) => {
      //       console.log(savedItem)
      //       this.load()
      //     }).catch((err) => {
      //       console.error(err)
      //     });
      //   }
      // },
      listActions: [{
        title: "Clientes",
        help: "Clientes",
        color: "orange",
        action: async (item) => {
          window.VMA.loading(true);
          try {
            const clients = await ApiService({
              url: "/client?all=true",
              method: "get",
            });
            const client = await ApiService({
              url: `/user/one/${item.id}`,
              method: "get",
            });
            const form = new Form({
              schema: clients.reduce((acc, bc) => ({
                ...acc,
                [bc.id]: {
                  label: bc.name,
                  type: Boolean,
                  changeOnLoad: false,
                  onChange: async (val, fields) => {
                    fields[bc.id].loading = true
                    if (val) {
                      ApiService({
                        url: `/user/${item.id}/clients/add/${bc.id}`,
                        method: 'post',
                      }).catch((err) => {
                        fields[bc.id].setValueWithoutTriggers(!val)
                        window.VMA.$emit('SHOW_SNACKBAR', {
                          text: `Error al agregar cliente a "${bc.name}"`,
                          color: 'error'
                        });
                        console.error({ err })
                      }).finally(() => {
                        fields[bc.id].loading = false
                      })
                    } else {
                      ApiService({
                        url: `/user/${item.id}/clients/remove/${bc.id}`,
                        method: 'delete',
                      }).catch((err) => {
                        fields[bc.id].setValueWithoutTriggers(!val)
                        window.VMA.$emit('SHOW_SNACKBAR', {
                          text: `Error al eliminar cliente a "${bc.name}"`,
                          color: 'error'
                        });
                        console.error({ err })
                      }).finally(() => {
                        fields[bc.id].loading = false
                      })
                    }
                  }
                }
              }), {}),
              item: (client.clients || []).reduce((acc, bc) => ({
                ...acc,
                [bc.id]: true
              }), {}),
            })
            const dialog = new Dialog({
              title: 'Clientes',
              subtitle: item.name
            })
            dialog.open()
            dialog.append(form)
          } catch (err) {
            // ok
            console.error(err);
            window.VMA.showError({ title: "Error al cargar los Clientes" });
          } finally {
            window.VMA.loading(false);
          }
        },
      }, {
        help: 'Cambiar contraseña',
        icon: 'mdi-account-key',
        color: 'green',
        condition: (item) => item.id === this.getUser.id || this.getUser.rol === 'admin',
        action: (item) => {
          UsersService.changePassword({
            user: item
          }, this)
        }
      }, {
        help: window.VMA.$vuetify.lang.t(`$vuetify.${this.model}.read`),
        icon: 'mdi-eye',
        color: 'secondary',
        action: (item) => {
          Crud.read({
            item,
            schema: Users,
            model: this.model,
            title: this.getTitle(item)
          })
        }
      }, {
        help: window.VMA.$vuetify.lang.t(`$vuetify.${this.model}.delete`),
        icon: 'mdi-delete',
        color: 'red',
        action: (item) => {
          Crud.delete({
            id: item.id,
            model: this.model,
            title: this.getTitle(item)
          }).then((savedItem) => {
            console.log(savedItem)
            this.load()
          }).catch((err) => {
            console.error(err)
          });
        }
      }],
    }
  },
  mounted() {
    this.load()
  },
  methods: {
    getTitle: (item) => item.name,
    getSubTitle: (item) => item.email,
    getExtra: (item) => item.password,
    load() {
      this.loading = true
      ApiService({
        url: '/user',
        method: 'get',
      }).then((resp) => {
        this.items = resp
        this.loading = false
      }).catch((err) => {
        this.loading = false
        console.log(err)
      })
    },
  },
  computed: {
    ...mapGetters('auth', ['getUser']),
  },
}
</script>
