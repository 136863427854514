import Dialog from '@/components/dialog'
import Form from '@/components/form'
import ApiService from '@/util/api.service'

const changePassword = async ({
  user
}) => {
  const form = new Form({
    schema: {
      password: {
        label: 'Nueva Contraseña',
        type: String,
        password: true
      },
      repeat_password: {
        label: 'Confirmar Contraseña',
        type: String,
        password: true
      }
    }
  })
  const dialog = new Dialog({
    title: 'Cambiar contraseña',
    subtitle: user.name,
    actions: [{
      help: 'Guardar',
      color: 'success',
      icon: 'mdi-content-save',
      action: () => {
        const item = form.getItem()
        form.form.fields.password.removeError()
        form.form.fields.repeat_password.removeError()
        if (form.form.hasErrors()) {
          return
        }
        if (item.password !== item.repeat_password) {
          form.form.fields.password.setError('La contraseña no coincide')
          form.form.fields.repeat_password.setError('La contraseña no coincide')
          return
        }
        let loader;
        // if (scope) {
        //   loader = scope.$loading.show({
        //     loader: 'dots'
        //   })
        // }
        ApiService({
          url: '/auth/changePassword',
          method: 'put',
          data: { userId: user.id, password: item.password }
        }).then(() => {
          dialog.close()
          if (loader) {
            loader.hide()
          }
        }).catch(console.error)
      }
    }]
  })
  dialog.open()
  dialog.append(form)
}

export default {
  changePassword,
}
