export default {
  id: {
    type: Number,
    visibility: false
  },
  name: {
    label: 'Nombre',
    type: String
  },
  email: {
    label: 'Correo electronico',
    type: String
  },
  rol: {
    label: 'Rol',
    type: String,
    default: 'client',
    options: [{
      id: 'admin',
      name: 'Administrador'
    }, {
      id: 'client',
      name: 'Cliente'
    }]
  },
  password: {
    label: 'Contraseña',
    type: String,
    visibility: (item) => item.id === undefined,
    min: 8,
    max: 100
  },
};
